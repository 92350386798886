import React from "react";
import { Image } from "../../../types";
import { parse } from "../../../utils/component";
import { addWidthParameter } from "../../../utils/image-utils";

interface QuoteImageProps {
  title?: string;
  author?: string;
  text?: string;
  image?: Image;
  reverse?: boolean;
  anchorRef?: string;
}

const QuoteImage: React.FC<QuoteImageProps> = ({ title, author, text, image, reverse, anchorRef }) => {
  let className = "twocolumn";
  if (reverse) {
    className = "twocolumn twocolumn--reverse";
  }

  return (
    <div className={className} id={anchorRef}>
      <div className="twocolumn__container">
        {title && (
          <div className="twocolumn__row">
            <div className="twocolumn__column">
              {title && (
                <div className="heading-seperator">
                  <h3>{title}</h3>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="twocolumn__row">
          <div className="twocolumn__column">{image && <img src={addWidthParameter(parse(image?.url), 500)} alt={parse(image?.altText)} />}</div>
          <div className="twocolumn__column">
            <div className="quote">
              <blockquote className="quote__text">{text}</blockquote>
              <span className="quote__author">- {author}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteImage;
