import React from "react";
import { Image } from "../../../types";
import { parse } from "../../../utils/component";
import { addWidthParameter } from "../../../utils/image-utils";

interface TextAndImageProps {
  title?: string;
  text?: string;
  image?: Image;
  reverse?: boolean;
  anchorRef?: string;
}

const TextAndImage: React.FC<TextAndImageProps> = ({ title, text, image, reverse, anchorRef }) => {
  let className = "twocolumn";
  if (reverse) {
    className = "twocolumn twocolumn--reverse";
  }

  return (
    <div className={className} id={anchorRef}>
      <div className="twocolumn__container">
        <div className="twocolumn__row">
          <div className="twocolumn__column">
            {title && (
              <div className="heading-seperator">
                <h3>{title}</h3>
              </div>
            )}
            {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
          </div>
          <div className="twocolumn__column">
            {image && <img src={addWidthParameter(parse(image?.url), 500)} className="textblock__image" alt={parse(image?.altText)} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAndImage;
